
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import base from "@/api/base.js";
import ElConfigProvider from "element-plus";
import ko from "element-plus/lib/locale/lang/ko";

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
    ElConfigProvider,
  },

  data() {
    const validation = Yup.object().shape({
      manager: Yup.string().required("필수값입니다."),
      // checkDate: Yup.string().required("필수값입니다."),
      checkStore: Yup.array().required("필수값입니다."),
      checkVersion: Yup.string().required("필수값입니다."),
      // checkCase: Yup.string().required("필수값입니다."),
    });
    //     const valid = awiat validation = Yup.object().shape({
    // .isValid(this.schedule)
    return {
      companies: [],
      brands: [],
      users: [],
      stores: [],
      checkitemversions: [],
      schedule: { company: {}, brand: {}, store: {}, inspector: {} },
      allSelected: "false",
      storeIds: [],
      searchcode: "store_nm",
      searchdata: "",
      isPlan: true,
      validation,
      ko,
    };
  },

  mounted() {
    base
      .getCompanyList()
      .then((res) => {
        console.log(res);
        this.companies = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    base
      .getBrandList()
      .then((res) => {
        console.log(res);
        this.brands = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    base
      .getUserList()
      .then((res) => {
        console.log(res);
        this.users = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    base
      .getregStoreList()
      .then((res) => {
        console.log(res);
        this.stores = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    base
      .getCheckVersionList()
      .then((res) => {
        console.log(res);
        this.checkitemversions = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    async submitForm() {
      this.schedule.store = this.storeIds;
      console.log("newschedule", this.schedule);
      await base
        .newSchedule(this.schedule)
        .then((res) => {
          console.log(res);

          if (res.status == 200) {
            alert("신규 일정을 등록했습니다.");
            this.$router.push("/schedules");
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    selectAll() {
      this.storeIds = [];
      if (this.allSelected) {
        for (const store in this.stores) {
          this.storeIds.push(this.stores[store].id);
        }
      }
      console.log(this.storeIds);
    },

    select() {
      this.allSelected = false;
    },
    getSearchResult() {
      const search = {
        [this.searchcode]: this.searchdata,
      };
      base
        .getregStoreList(search)
        .then((res) => {
          console.log(res);
          this.stores = res.data;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
    disabledDate(time) {
      const nowDate = new Date();
      const yesterDate = nowDate.getTime() - 1 * 24 * 60 * 60 * 1000;
      return yesterDate > time.getTime();
    },
    onChangeCheckVersion() {
      if (this.checkitemversions.period_type == 'PLAN') {
        this.isPlan = true;
      } else {
        this.isPlan = false;
      }
    }
  },
});
